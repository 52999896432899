<template>
  <div class="body">
    <div style="width: 100%; height: 10vh;">
      <img id="top" src="/static/images/kanban/kanbanTop.gif" style="width: 100%"/>
    </div>
    <!--    上半部分-->
    <div style="width: 100%; height: 60vh;">
      <!--      左上方部分-->
      <div style="width: 40%; height: 60vh; float: left;">
        <div
          v-loading="activeCountLoading"
          element-loading-background="rgba(0, 0, 0, 0.4)"
          style="width: 100%; height: 13vh;">
          <!--          上左方已认证电梯数量,已认证电梯配件数量,入网电梯数量,在线电梯数量-->
          <div class="vm-separate">
            <div style="height: 14vh; margin: 0 0.2% 0 1.8%;">
              <div style="width: 100%; height: 9vh; margin: 0 auto; position: relative; z-index: 2;">
                <div
                  v-for="(item, index) of elevatorCountDataStr"
                  :key="index"
                  style="width: 2vh; display: inline-block;">
                  <span class="numberFont">{{item}}</span>
                </div>
              </div>
              <div
                style="width: 100%; height: 6vh; margin-top: -4vh; position: relative; z-index: 1; background: url(/static/images/kanban/totalnumbg.png) no-repeat; background-size: 100% 100%;">
                <span style="line-height: 5.2; font-size: 1.7vh;">入网电梯数量</span>
              </div>
            </div>
            <div style="height: 14vh; margin: 0 0.2% 0 0.2%;">
              <div style="width: 100%; height: 9vh; margin: 0 auto; position: relative; z-index: 2;">
                <div
                  v-for="(item, index) of elevatorOnlineStr"
                  :key="index"
                  style="width: 2vh; display: inline-block;">
                  <span class="numberFont">{{item}}</span>
                </div>
              </div>
              <div
                style="width: 100%; height: 6vh; margin-top: -4vh; position: relative; z-index: 1; background: url(/static/images/kanban/totalnumbg.png) no-repeat; background-size: 100% 100%;">
                <span style="line-height: 5.2; font-size: 1.7vh;">在线电梯数量</span>
              </div>
            </div>
            <div style="height: 14vh; margin: 0 0.2% 0 0.2%;">
              <div style="width: 100%; height: 9vh; margin: 0 auto; position: relative; z-index: 2;">
                <div
                  v-for="(item, index) of elevatorsNumStr"
                  :key="index"
                  style="width: 2vh; display: inline-block;">
                  <span class="numberFont">{{item}}</span>
                </div>
              </div>
              <div
                style="width: 100%; height: 6vh; margin-top: -4vh; position: relative; z-index: 1; background: url(/static/images/kanban/totalnumbg.png) no-repeat; background-size: 100% 100%;">
                <span style="line-height: 5.2; font-size: 1.7vh;">已认证电梯数量</span>
              </div>
            </div>
            <div style="height: 14vh; margin: 0 1.8% 0 0.2%;">
              <div style="width: 100%; height: 9vh; margin: 0 auto; position: relative; z-index: 2;">
                <div
                  v-for="(item, index) of elevatorPartsNumStr"
                  :key="index"
                  style="width: 2vh; display: inline-block;">
                  <span class="numberFont">{{item}}</span>
                </div>
              </div>
              <div
                style="width: 100%; height: 6vh; margin-top: -4vh; position: relative; z-index: 1; background: url(/static/images/kanban/totalnumbg.png) no-repeat; background-size: 100% 100%;">
                <span style="line-height: 5.2; font-size: 1.7vh;">已认证电梯配件数量</span>
              </div>
            </div>
          </div>
        </div>
        <!--        下边地图-->
        <div style="width: 100%; height: 48vh; margin-top: -1vh;">
          <div
            style="height: 96%; margin: 0 2%; border: 1px #4e43f0 solid; border-radius: 2vh; background-color: #001FA3; box-shadow: rgb(11, 234, 235) 0 0 0.3vh; position: relative;">
            <!--            地图-->
            <china-map style="width:100%; height:100%; position: absolute; z-index: 1;"></china-map>
            <!--            点击地图电梯弹出个梯信息-->
            <div
              v-if="loadElevator"
              style="width: 40%; height: 100%; position: absolute; z-index: 2; margin-left: 0%; border-radius: 0 2vh 2vh 0; background-color: black; opacity: 0.7;">
              <div style="width: 100%; height: 100%; margin: 0 auto;">
                <div style="width: 96%; height: 100%; margin: 0 2%;">
                  <div style="width: 30%; height: 4vh; float: left;">
                    <div style="font-size: 1.5vh; margin-top: 1vh; margin-left: 1vh;text-align: left">
                      电梯列表
                    </div>
                    <div
                      style="width: 90%; height: 0.4vh; background: url(/static/images/kanban/subtitleline.png) no-repeat; background-size: 100% 100%;">
                    </div>
                  </div>
                  <div style="width: 40px; height: 4vh; float: right;">
                    <div
                      style="width: 90%; height: 1.9vh; margin-top: 1.2vh;cursor:pointer"
                      @click="loadElevator=false">
                      <i class="el-icon-circle-close"></i>
                    </div>
                  </div>
                  <div
                    style="width: 100%; height: 90%; font-size: 1vh; margin-top: -1.5vh; text-align: left;overflow-y:auto">
                    <el-table
                      :data="elevatorData"
                      stripe
                      :show-header="false"
                      style="width: 100%;cursor:pointer"
                      @row-click="getElevatorPartsData">
                      <el-table-column
                        prop="elevatorName"
                        label="地址">
                      </el-table-column>
                    </el-table>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="loadElevatorParts"
              style="width: 50%; height: 100%; position: absolute; z-index: 2; margin-left: 50%; border-radius: 0 2vh 2vh 0; background-color: black; opacity: 0.7;">
              <div style="width: 100%; height: 100%; margin: 0 auto;">
                <div style="width: 98%; height: 16vh;">
                  <div
                    style="width: 40%;margin-left: 1vh; font-size: 0.8vh; line-height: 2vh; padding-top: 10px;text-align: left;float: left;">
                    <i class="el-icon-d-arrow-right"></i>&nbsp;当前整梯EOCD曲线
                  </div>
                  <div
                    style="width: 40%; font-size: 0.8vh; line-height: 2vh;padding-top: 10px; text-align: center;float: left;">
                    最大加速度:&nbsp;{{curve1s.max}}&nbsp;&nbsp;最大减速度:&nbsp;{{curve1s.min}}
                  </div>
                  <div style="width: 40px; height: 4vh; float: right;">
                    <div
                      style="width: 90%; height: 1.9vh; margin-top: 1.0vh;cursor:pointer"
                      @click="loadElevatorParts=false">
                      <i class="el-icon-circle-close"></i>
                    </div>
                  </div>
                  <div
                    style="width: 100%; height: 16vh;">
                    <high-charts-spline
                      id="spline1"
                      line-count="1"
                      :data="curve1s.data"
                      :color="curve1s.color"></high-charts-spline>
                  </div>
                </div>
                <el-divider></el-divider>
                <div style="width: 96%; height: 25vh; margin: 0 2%;">
                  <div style="width: 25%; height: 4vh; text-align: left;">
                    <div style="font-size: 1.5vh; margin-top: 1vh; margin-left: 1vh;">
                      配件信息
                    </div>
                    <div
                      style="width: 90%; height: 0.4vh; background: url(/static/images/kanban/subtitleline.png) no-repeat; background-size: 100% 100%;">
                    </div>
                  </div>
                  <div
                    style="width: 100%; height: 22vh; font-size: 1vh; margin-top: -1.5vh; text-align: left;overflow-y:auto">
                    <div v-for="(item,index) in elevatorPartsData" :key="index" style="margin: 0 0 0.5vh 1vh;">
                      <div style="display: inline-block;width: 80px;text-align: right">{{item.partsName}}：</div>
                      <div style="display: inline-block;">{{item.partsSn}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 60%; height: 60vh; float: right;">
        <div style="width: 70%; height: 60vh; float: left; margin-left: 1%;">
          <div style="width: 100%; height: 20vh;">
            <div
              v-loading="jxSummaryLoading"
              element-loading-background="rgba(0, 0, 0, 0.4)"
              style="width: 49%; height: 18.5vh; background: url(/static/images/kanban/piebg.png) no-repeat; background-size: 100% 100%; float: left;">
              <div style="width: 40%; height: 18vh; float: left;">
                <div class="subTitle">
                  故障类型
                </div>
                <div
                  style="width: 90%; margin-left: 0.5vh; height: 0.4vh; margin-top: 0.5vh; background: url(/static/images/kanban/subtitleline.png) no-repeat; background-size: 100% 100%;">
                </div>
                <pie-title
                  id="faultTypeTitle"
                  :data="faultType.data"
                  :colors="faultType.color"></pie-title>
              </div>
              <div style="width: 60%; height: 18vh; float: right;">
                <high-charts-pie
                  id="faultTypePie"
                  :data-label="false"
                  :data="faultType.data"
                  :colors="faultType.color"></high-charts-pie>
              </div>
            </div>
            <div
              style="width: 49%; height: 18.5vh; background: url(/static/images/kanban/piebg.png) no-repeat; background-size: 100% 100%; float: right;">
              <div style="width: 40%; height: 18vh; float: left;">
                <div class="subTitle">
                  区域分布
                </div>
                <div
                  style="width: 90%; margin-left: 0.5vh; height: 0.4vh; margin-top: 0.5vh; background: url(/static/images/kanban/subtitleline.png) no-repeat; background-size: 100% 100%;">
                </div>
                <pie-title
                  id="regionTitle"
                  :data="region.data"
                  :colors="region.color"></pie-title>
              </div>
              <div style="width: 60%; height: 18vh; float: right;">
                <high-charts-pie
                  id="regionPie"
                  :data-label="false"
                  :data="region.data"
                  :colors="region.color"></high-charts-pie>
              </div>
            </div>
          </div>
          <div style="width: 100%; height: 20vh;">
            <div
              style="width: 49%; height: 18.5vh; background: url(/static/images/kanban/piebg.png) no-repeat; background-size: 100% 100%; float: left;">
              <div style="width: 40%; height: 18vh; float: left;">
                <div class="subTitle">
                  品牌分布
                </div>
                <div
                  style="width: 90%; margin-left: 0.5vh; height: 0.4vh; margin-top: 0.5vh; background: url(/static/images/kanban/subtitleline.png) no-repeat; background-size: 100% 100%;">
                </div>
                <pie-title
                  id="brandTitle"
                  :data="brand.data"
                  :colors="brand.color"></pie-title>
              </div>
              <div style="width: 60%; height: 18vh; float: right;">
                <high-charts-pie
                  id="regionBrand"
                  :data-label="false"
                  :data="brand.data"
                  :colors="brand.color"></high-charts-pie>
              </div>
            </div>
            <div
              style="width: 49%; height: 18.5vh; background: url(/static/images/kanban/piebg.png) no-repeat; background-size: 100% 100%; float: right;">
              <div style="width: 40%; height: 18vh; float: left;">
                <div class="subTitle">
                  配件类型
                </div>
                <div
                  style="width: 90%; margin-left: 0.5vh; height: 0.4vh; margin-top: 0.5vh; background: url(/static/images/kanban/subtitleline.png) no-repeat; background-size: 100% 100%;">
                </div>
                <pie-title
                  id="elevatorPartsTypeTitle"
                  :data="elevatorPartsType.data"
                  :colors="elevatorPartsType.color"></pie-title>
              </div>
              <div style="width: 60%; height: 18vh; float: right;">
                <high-charts-pie
                  id="elevatorPartsTypePie"
                  :data-label="false"
                  :data="elevatorPartsType.data"
                  :colors="elevatorPartsType.color"></high-charts-pie>
              </div>
            </div>
          </div>
          <div style="width: 100%; height: 20vh;">
            <div
              v-loading="jxSummaryLoading"
              element-loading-background="rgba(0, 0, 0, 0.4)"
              style="width: 100%; height: 19vh; margin: 0vh; background: url(/static/images/kanban/piebg.png) no-repeat; background-size: 100% 100%; float: left;">
              <div style="width: 100%; height: 3vh; margin-top: 1vh;">
                <div style="width: 30%;">
                  <div style="font-size: 1.6vh;">
                    电梯故障次数统计
                  </div>
                  <div
                    style="width: 90%; margin-left: 1vh; height: 0.4vh; margin-top: 0.5vh; background: url(/static/images/kanban/subtitleline.png) no-repeat; background-size: 100% 100%;">
                  </div>
                </div>
              </div>
              <div style="width: 100%; height: 16vh; margin-top: -1vh;">
                <high-charts-bar
                  id="bar1"
                  :categories="faultTimes.categories"
                  :data="faultTimes.data"
                  :colors="['#00e2dc']"></high-charts-bar>
              </div>
            </div>
          </div>
        </div>
        <div style="width: 28%; height: 60vh; float: right;">
          <div
            style="height: 29vh; margin: 0 1vh 0 0; background: url(/static/images/kanban/piebg.png) no-repeat; background-size: 100% 100%;">
            <div
              style="width: 40%; margin: 0 0 0.5vh 1vh; font-size: 1.6vh; line-height: 2vh; text-align: left;padding-top: 5px;float: left;">
              <i class="el-icon-d-arrow-right"></i>&nbsp;门机故障
            </div>
            <vue-seamless-scroll ref="DoorFaultScroll" :data="doorFault" class="warpRight">
              <ul>
                <li v-for="(item, index) in doorFault" :key="index">
                  <div style="background: rgba(59,60,140,0.2)">
                    <div style="font-size: 1.2vh; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{item.elevatorName}}</div>
                    <div style="font-size: 1.2vh; color: #f9d76e; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{item.faultDesc}}</div>
                    <div style="font-size: 1.0vh">{{item.faultTime}}</div>
                  </div>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
          <div
            style="height: 29vh; margin: 1vh 1vh 0 0; background: url(/static/images/kanban/piebg.png) no-repeat; background-size: 100% 100%;">
            <div
              style="width: 40%; margin: 0 0 0.5vh 1vh; font-size: 1.6vh; line-height: 2vh; text-align: left;padding-top: 5px;float: left;">
              <i class="el-icon-d-arrow-right"></i>&nbsp;安全隐患
            </div>
            <vue-seamless-scroll ref="EocdFaultScroll" :data="eocdFault" class="warpRight">
              <ul>
                <li v-for="(item, index) in eocdFault" :key="index">
                  <div style="background: rgba(59,60,140,0.2)">
                    <div style="font-size: 1.2vh; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">{{item.elevatorName}}</div>
                    <div
                      style="font-size: 1.2vh; color: #f9d76e; white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
                      {{item.faultDesc}}
                    </div>
                    <div style="font-size: 1.0vh">{{item.faultTime}}</div>
                  </div>
                </li>
              </ul>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
    </div>
    <!--    下半部分-->
    <div style="width: 100%; height: 30vh;">
      <!--      左边维保数据-->
      <div style="width: 40%; height: 30vh; float: left;">
        <div
          v-loading="wbSummaryLoading"
          element-loading-background="rgba(0, 0, 0, 0.4)"
          style="height: 29vh; margin: 0 1vh; background: url(/static/images/kanban/2picsbg.png) no-repeat; background-size: 100% 100%;">
          <div style="width: 50%; float: left;">
            <div class="subTitle">
              实时故障
            </div>
            <div
              style="width: 40%; margin-left: 30%;height: 0.4vh; margin-top: 0.5vh; background: url(/static/images/kanban/subtitleline.png) no-repeat; background-size: 100% 100%;">
            </div>
            <div style="width: 100%; height: 25vh;">
              <vue-seamless-scroll ref="RealTimeScroll" :data="jxEvent" class="warp">
                <ul>
                  <li v-for="(item, index) in jxEvent" :key="index">
                    <div style="font-size: 1.4vh">{{item.elevatorName.length > 20 ? item.elevatorName.slice(0, 20) : item.elevatorName}}</div>
                    <div style="font-size: 1.2vh">
                      <span>{{item.createTime}}</span>
                      <span style="color: #f9d76e">  {{item.faultDesc}}</span>
                    </div>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
          <div style="width: 50%; float: right;">
            <div class="subTitle">
              实时事件
            </div>
            <div
              style="width: 40%; margin-left: 30%;height: 0.4vh; margin-top: 0.5vh; background: url(/static/images/kanban/subtitleline.png) no-repeat; background-size: 100% 100%;">
            </div>
            <div style="width: 100%; height: 25vh;">
              <vue-seamless-scroll ref="EventScroll" :data="mbEvent" class="warp">
                <ul>
                  <li v-for="(item, index) in mbEvent" :key="index">
                    <div style="font-size: 1.4vh">{{item.elevatorName.length > 20 ? item.elevatorName.slice(0, 20) : item.elevatorName}}</div>
                    <div style="font-size: 1.2vh">
                      <span>{{item.createTime}}</span>
                      <span style="color: #f9d76e">
                        <span v-if="item.faultDesc === 'powerLoss'">  主电源断电</span>
                        <span v-else-if="item.faultDesc === 'alarm'">  警铃报警</span>
                      </span>
                    </div>
                  </li>
                </ul>
              </vue-seamless-scroll>
            </div>
          </div>
        </div>
      </div>
      <!--      右边急修数据-->
      <div style="width: 60%; height: 30vh; float: right;">
        <div
          v-loading="elevatorSummaryLoading"
          element-loading-background="rgba(0, 0, 0, 0.4)"
          style="height: 29vh; margin: 0 1vh; background: url(/static/images/kanban/3picsbg.png) no-repeat; background-size: 100% 100%;">
          <div style="width: 33%; float: left; margin-left: 0.5%;">
            <div class="subTitle">
              {{eocdChange ? '整梯EOCD曲线' : '最新整梯加速度'}}
            </div>
            <div
              style="width: 40%; margin-left: 30%; height: 0.4vh; margin-top: 0.5vh; background: url(/static/images/kanban/subtitleline.png) no-repeat; background-size: 100% 100%;">
            </div>
            <div
              v-loading="top1CurveLoading"
              element-loading-background="rgba(0, 0, 0, 0.4)"
              style="width: 100%; height: 24vh;">
              <div
                style="font-size: 0.8vh; line-height: 2vh;padding-top: 10px;float: right; margin-right: 10px">
                {{eocdChange ? '速度max: ' + curve2s.max + '  速度min: ' + curve2s.min : '变加速度 最大值: ' + curve3s.max}}
              </div>
              <high-charts-spline
                id="spline"
                line-count="1"
                style="height: 22vh"
                :data="eocdChange ? curve2s.data : curve3s.data"
                :color="eocdChange ? curve2s.color : curve3s.color"></high-charts-spline>
            </div>
          </div>
          <div style="width: 33%; float: left;">
            <div class="subTitle">
              急修到场时间统计
            </div>
            <div
              style="width: 40%; margin-left: 30%; height: 0.4vh; margin-top: 0.5vh; background: url(/static/images/kanban/subtitleline.png) no-repeat; background-size: 100% 100%;">
            </div>
            <div style="width: 100%; height: 25vh;">
              <high-charts-cylinderr
                id="arriveTimesCylinderr"
                :categories="faultArriveTimes.categories"
                :data="faultArriveTimes.data"
                y-axis-txt="小时"
                :colors="['#009cff']"></high-charts-cylinderr>
            </div>
          </div>
          <div style="height: 29vh; width: 33%; float: left;">
            <div class="subTitle">
              急修完工时间统计
            </div>
            <div
              style="width: 40%; margin-left: 30%;height: 0.4vh; margin-top: 0.5vh; background: url(/static/images/kanban/subtitleline.png) no-repeat; background-size: 100% 100%;">
            </div>
            <div style="width: 100%; height: 25vh;">
              <high-charts-cylinderr
                id="doneTimesCylinderr"
                :categories="faultDoneTimes.categories"
                :data="faultDoneTimes.data"
                y-axis-txt="小时"
                :colors="['#009cff']"></high-charts-cylinderr>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import VueSeamlessScroll from "vue-seamless-scroll";
  import ChinaMap from "./ChinaMap";
  import HighChartsPie from "./HighChartsPie";
  import HighChartsBar from "./HighChartsBar.vue";
  import HighChartsSpline from "./HighChartsSpline.vue";
  import HighChartsCylinderr from "./HighChartsCylinderr.vue";
  import PieTitle from "@/views/KanBan/PieTitle";
  import auth from "@/util/auth";

  const reconnectInterval = 5000; // 重试间隔时间,5秒

  export default {
    components: {
      PieTitle, VueSeamlessScroll,
      ChinaMap, HighChartsPie, HighChartsBar, HighChartsSpline, HighChartsCylinderr,
    },
    data() {
      return {
        ws: null,
        reconnectNum: 3, // 重试次数
        lockReconnect: false, // 避免重复连接
        currentUserType: auth.getUserType(),
        loadElevator: false,
        loadElevatorParts: false,
        top10CurveLoading: false,
        top1CurveLoading: false,
        elevatorSummaryLoading: false,
        activeCountLoading: false,
        jxSummaryLoading: false,
        wbSummaryLoading: false,
        elevatorDataLoading: false,
        elevatorPartsDataLoading: false,
        elevatorSelectCurveLoading: false,
        eocdChange: false,
        elevatorData: [],
        elevatorPartsData: [],
        elevatorCountDataStr: "00000",
        elevatorOnlineStr: "00000",
        elevatorsNumStr: "00000",
        elevatorPartsNumStr: "00000",
        elevatorType: {data: [], color: ["#8e85ff", "#c5c0ff", "#dddaff", "#5247f4", "#4433e7"]},
        region: {data: [], color: ["#a54612", "#fbc6aa", "#943501", "#f8a97f", "#f28a53"]},
        brand: {data: [], color: ["#fff1c4", "#a17f0b", "#745b06", "#f9d76e", "#c19d23"]},
        elevatorPartsType: {data: [], color: ["#f6d8ff", "#e3aff1", "#c88dd9", "#a555ba", "#8a6095"]},
        faultType: {data: [], color: ["#cafff9", "#49ebd9", "#1cc8b6", "#019a8a", "#15675e", "#81fff1", "#29d8c5"]},
        curve1s: {data: [], max: 0, min: 0, color: ["#E4007F"]},
        curve2s: {data: [], max: 0, min: 0, color: ["#E4007F"]},
        curve3s: {data: [], max: 0, min: 0, color: ["#E4007F"]},
        curve4s: {
          data: [],
          max: 0,
          min: 0,
          color: ["#E4007F", "#e3aff1", "#1cc8b6", "#81fff1", "#f28a53", "#4433e7", "#dddaff", "#f28a53", "#8e85ff", "#a54612"],
        },
        faultTimes: { // 故障次数
          categories: [],
          data: [],
        },
        faultArriveTimes: { // 急修到场时间
          categories: [],
          data: [],
        },
        faultDoneTimes: { // 急修完工时间
          categories: [],
          data: [],
        },
        maintenanceCycle: { // 维保周期
          categories: [],
          data: [],
        },
        maintenanceTimelinessRate: {
          categories: [],
          data: [],
        },
        mbEvent: [],
        jxEvent: [],
        eocdFault: [],
        doorFault: [],
      };
    },
    mounted() {
      this.iniLoad();
      this.getMbEvent();
      this.getJxEvent();
      this.getEocdFault();
      this.getDoorFault();
      setInterval(() => {
        this.iniLoad();
      }, 1000 * 60 * 60);
      setInterval(() => {
        this.getMbEvent();
        this.getJxEvent();
        this.getEocdFault();
        this.getDoorFault();
        this.$refs.EventScroll.reset();
        this.$refs.RealTimeScroll.reset();
        this.$refs.EocdFaultScroll.reset();
        this.$refs.DoorFaultScroll.reset();
        this.eocdChange = !this.eocdChange;
      }, 1000 * 60 * 2);
    },
    methods: {
      test() {
        this.getECODTop10();
      },
      iniLoad() {
        this.createWs();
        this.getECODTop1();
        this.getECODTop10();
        this.activeCount();
        this.elevatorSummary();
        this.jxSummary();
        this.wbSummary();
        this.getElevatorCount();
        this.getOnlineElevatorCount();
      },
      createWs() {
        if (this.ws != null) {
          this.ws.close();
        }
        try {
          this.initEventHandle();
        } catch (e) {
          this.reconnectWs();
        }
      },
      reconnectWs() {
        if (!this.lockReconnect) {
          this.lockReconnect = true;
          // 没连接上会一直重连，设置延迟避免请求过多
          setTimeout(() => {
            if (this.reconnectNum > 0) {
              this.reconnectNum--;
              this.createWs();
              this.lockReconnect = false;
            }
          }, reconnectInterval);
        }
      },
      initEventHandle() {
        // 监听是否连接成功
        this.ws.onopen = () => {
          console.log("ws连接状态：" + this.ws.readyState);
          // 连接成功则发送一个数据
          this.ws.send(auth.getUsername()+"-"+auth.getCurrentOrgId());
        },
        // 接听服务器发回的信息并处理展示
        this.ws.onmessage = (event) => {
          try {
            let data = JSON.parse(event.data);
            if (data.type === 1) {
              // this.showNotice(data.elevatorNo + "-" + data.elevatorName, "以下电梯产生急修");
            }
            if (data.type === 2) {
              this.showNotice(data.elevatorNo + "," + data.msg, "以下电梯EOCD检测振动超标");
            }
            if (data.type === 3) {
              this.showNotice(data.elevatorNo + "-" + data.elevatorName, "以下电梯配件已更换");
            }
            if (data.type === 4) {
              this.showNotice(data.elevatorNo + "-" + data.elevatorName, "以下电梯配件已认证");
            }
          }catch(e) {
            console.log(event);
          }
        },
        // 监听连接关闭事件
        this.ws.onclose = () => {
          // 监听整个过程中websocket的状态
          console.log("ws连接状态：" + this.ws.readyState);
        },
        // 监听并处理error事件
        this.ws.onerror = (error) => {
          console.log(error);
          this.reconnectWs();
        };
      },
      showNotice(data,title) {
        //发出警报
        this.$notify({
          title: title,
          message: data,
          position: "bottom-right",
          type: "warning",
          duration: 15000,
          dangerouslyUseHTMLString: true,
          customClass: "eventNotice",
        });
      },
      activeCount() {
        this.activeCountLoading = true;
        this.$api.getData("board/activatedCount").then(res => {
          if (res.status === 200) {
            this.elevatorsNumStr = res.data["elevatorsNum"].toString();
            this.elevatorPartsNumStr = res.data["elevatorPartsNum"].toString();
            this.activeCountLoading = false;
          }
        }).catch(() => {
          this.activeCountLoading = false;
        });
      },
      elevatorSummary() {
        this.elevatorSummaryLoading = true;
        this.$api.getData("board/elevator/summary").then(res => {
          if (res.status === 200) {
            this.elevatorType.data = this.convertToArr(res.data["elevatorType"]);
            this.brand.data = this.convertToArr(res.data["brand"]);
            this.region.data = this.convertToArr(res.data["region"]);
            this.elevatorPartsType.data = this.convertToArr(res.data["elevatorPartsType"]);
            this.elevatorSummaryLoading = false;
          }
        }).catch(() => {
          this.elevatorSummaryLoading = false;
        });
      },
      jxSummary() {
        this.jxSummaryLoading = true;
        this.$api.getData("board/jx").then(res => {
          if (res.status === 200) {
            this.faultType.data = this.convertToArr(res.data["faultType"]);
            let faultTimesArr = this.convertToBarArr(res.data["faultTimes"]);
            this.faultTimes.categories = faultTimesArr[0];
            this.faultTimes.data = faultTimesArr[1];
            let faultArriveTimesArr = this.convertToBarArr(res.data["faultArriveTimes"]);
            this.faultArriveTimes.categories = faultArriveTimesArr[0];
            this.faultArriveTimes.data = faultArriveTimesArr[1];
            let faultDoneTimesArr = this.convertToBarArr(res.data["faultDoneTimes"]);
            this.faultDoneTimes.categories = faultDoneTimesArr[0];
            this.faultDoneTimes.data = faultDoneTimesArr[1];
            this.jxSummaryLoading = false;
          }
        }).catch(() => {
          this.jxSummaryLoading = false;
        });
      },
      wbSummary() {
        // this.wbSummaryLoading = true;
        // this.$api.getData("board/wb").then(res => {
        //   if (res.status === 200) {
        //     let maintenanceTimelinessRateArr = this.convertToBarArr(res.data["wbRate"]);
        //     this.maintenanceTimelinessRate.categories = maintenanceTimelinessRateArr[0];
        //     this.maintenanceTimelinessRate.data = maintenanceTimelinessRateArr[1];
        //     let maintenanceCycleArr = this.convertToBarArr(res.data["wbCycle"]);
        //     this.maintenanceCycle.categories = maintenanceCycleArr[0];
        //     this.maintenanceCycle.data = maintenanceCycleArr[1];
        //     this.wbSummaryLoading = false;
        //   }
        // }).catch(() => {
        //   this.wbSummaryLoading = false;
        // });
      },
      getElevatorCount() {
        this.$api.getData("elevators/count").then(res => {
          let count = 0;
          for (let item of res.data) {
            count = item.value + count;
          }
          this.elevatorCountDataStr = count.toString();
        }).catch(error => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      getOnlineElevatorCount() {
        this.$api.getData("elevators/countOnline").then(res => {
          let count = res.data;
          this.elevatorOnlineStr = count.toString();
        }).catch(error => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      getElevatorData(useUnitId) {
        this.elevatorDataLoading = true;
        this.$api.getData("board/elevator/" + useUnitId).then(res => {
          if (res.status === 200) {
            this.elevatorData = res.data;
            this.elevatorDataLoading = false;
          }
        }).catch(() => {
          this.elevatorDataLoading = false;
        });
      },
      getElevatorPartsData(row) {
        this.loadElevatorParts = true;
        this.elevatorPartsDataLoading = true;
        this.$api.getData("board/elevatorParts/" + row.elevatorId).then(res => {
          if (res.status === 200) {
            this.elevatorPartsData = res.data;
            this.elevatorPartsDataLoading = false;
          }
        }).catch(() => {
          this.elevatorPartsDataLoading = false;
        });
        this.getECODElevator(row.elevatorId);
      },
      getECODElevator(elevatorId) {
        this.elevatorSelectCurveLoading = true;
        this.$api.getData("board/ECOD/1" + elevatorId).then(res => {
          if (res.status === 200) {
            this.elevatorSelectCurveLoading = false;
            this.curve1s.data = [{
              data: [],
            }];
            this.curve1s.max = 0;
            this.curve1s.min = 0;
            if (res.data.length > 0) {
              this.bindVelocityInfo(res.data[0], this.curve1s, 0);
            }
          }
        }).catch(() => {
          this.elevatorSelectCurveLoading = false;
        });
      },
      getECODTop1() {
        this.top1CurveLoading = true;
        this.$api.getData("board/ECOD/top/1").then(res => {
          if (res.status === 200) {
            this.curve2s.data = [{
              data: [],
            }];
            this.curve2s.max = 0;
            this.curve2s.min = 0;
            this.curve3s.data = [{
              data: [],
            }];
            this.curve3s.max = 0;
            this.curve3s.min = 0;
            if (res.data.length > 0) {
              this.bindVelocityInfo(res.data[0], this.curve2s, 0);
              this.bindAccmInfo(res.data[0], this.curve3s, 0);
              this.top1CurveLoading = false;
            }
          }
        }).catch(() => {
          this.top1CurveLoading = false;
        });
      },
      bindVelocityInfo(data, curves, index) {
        let length = data.velocity.length;
        let fs = data.fs;
        for (let i = 0; i < length; i++) {
          let x = i / fs;
          let velocity = data.velocity[i];
          if (velocity > curves.max) {
            curves.max = velocity.toFixed(3);
          }
          if (velocity < curves.min) {
            curves.min = velocity.toFixed(3);
          }
          curves.data[index].data.push([x, velocity]);
        }
      },
      bindAccmInfo(data, curves, index) {
        let length = data.accm.length;
        let fs = data.fs;
        for (let i = 0; i < length; i++) {
          let x = i / fs;
          let accm = data.accm[i];
          if (accm > curves.max) {
            curves.max = accm.toFixed(3);
          }
          if (accm < curves.min) {
            curves.min = accm.toFixed(3);
          }
          curves.data[index].data.push([x, accm]);
        }
      },
      getECODTop10() {
        this.top10CurveLoading = true;
        this.$api.getData("board/ECOD/top/5").then(res => {
          if (res.status === 200) {
            this.curve4s.data = [];
            this.curve4s.max = 0;
            this.curve4s.min = 0;
            if (res.data.length > 0) {
              let dataLength = res.data.length;
              for (let i = 0; i < dataLength; i++) {
                let dd = {data: []};
                this.curve4s.data.push(dd);
                this.bindAccmInfo(res.data[i], this.curve4s, i);
                this.top10CurveLoading = false;
              }
            }
          }
        }).catch(() => {
          this.top10CurveLoading = false;
        });
      },
      showElevators(useUnitId) {
        this.loadElevator = true;
        this.getElevatorData(useUnitId);
      },
      convertToBarArr(obj) {
        let arr = [[], []];
        obj.forEach((item) => {
          arr[0].push(item.name);
          arr[1].push(item.total);
        });
        return arr;
      },
      convertToArr(obj) {
        let arr = [];
        obj.forEach((item) => {
          let subArr = [];
          subArr.push("");
          subArr.push(item.total);
          subArr.push(item.name);
          arr.push(subArr);
        });
        return arr;
      },
      getMbEvent() {
        this.$api.getData("board/mb").then(res => {
          this.mbEvent = res.data;
        }).catch(error => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      getJxEvent() {
        this.$api.getData("board/workOrder").then(res => {
          this.jxEvent = res.data.records;
        }).catch(error => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      getEocdFault() {
        let param = {
          pageSize: 8,
        };
        this.$api.getData("eocdFault", param).then(res => {
          this.eocdFault = res.data.records;
        }).catch(error => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      getDoorFault() {
        let param = {
          pageSize: 8,
          faultType: 30,
        };
        this.$api.getData("faultRecords", param).then(res => {
          this.doorFault = res.data.records;
        }).catch(error => {
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
.body {
  height: 100vh;
  margin: 0 auto;
  position: relative;
  text-align: center;
  color: #ffffff;
  background-color: #000000;

  .numberFont {
    font-family: digitalSans;
    line-height: 7vh;
    font-size: 4vh;
    width: 100%;
  }

  .title {
    line-height: 4vh;
    font-size: 1.8vh;
  }

  .subTitle {
    font-size: 1.6vh;
    margin-top: 1vh;
  }

  .warp {
    height: 24vh;
    width: 100%;
    margin: 0 auto;
    overflow: hidden;
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      li,
      a {
        height: 60px;
        line-height: 20px;
        justify-content: space-between;
      }
    }
  }

  .warpRight {
    height: 25vh;
    width: 92%;
    margin-left: 20px;
    overflow: hidden;
    text-align: left;
    ul {
      list-style: none;
      padding: 0;
      margin: 0 auto;
      li,
      a {
        height: 70px;
        line-height: 20px;
      }
    }
  }
}
</style>
