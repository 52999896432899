<template>
  <div style="width: 100%; height: 100%;">
    <div
      v-for="(item, index) in data"
      :id="id"
      :key="index"
      style="font-size: 1.1vh; margin-top: 1vh;text-align: left">
      <div
        :style="{'background-color':colors[index],'width': '0.7vh', 'height': '0.7vh','margin-left': '1vh', 'display': 'inline-block'}"></div>&nbsp;
      <span>{{item[2]}}</span>
    </div>
  </div>
</template>
<script>


  export default {
    props: {
      id: {
        type: String,
      },
      data: {
        type: Array,
      },
      colors: {
        type: Array,
      },
    },
    data() {
      return {
        chart: {},
      };
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {

      },
    },
  };
</script>
