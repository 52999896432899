<template>
  <div
    v-loading="mapLoading"
    element-loading-background="rgba(0, 0, 0, 0.4)"
    style="width: 100%; height: 100%;">
    <div id="chart" style="width: 100%; height: 100%;"></div>
  </div>
</template>

<script>
  import * as echarts from "echarts";

  export default {
    name: "ChinaEcharts",
    data() {
      return {
        mapLoading: false,
        chart: {},
        useUnitData: [],
        myGeo: new window.BMap.Geocoder(),
        chartData: [],
      };
    },
    mounted() {
      this.initChart();
      this.getUseUnit();
    },
    methods: {
      initChart() {
        this.chart = echarts.init(document.getElementById("chart"));
        echarts.registerMap("china", require("@/assets/json/china.json"));
        window.onresize = this.chart.resize;
        this.chart.setOption(this.getChartOption());

        let that = this;
        this.chart.on("click", function(params) {
          if (params.componentType === "series") {
            that.$parent.showElevators(params.value[3]);
          }
        });
      },
      getUseUnit() {
        this.mapLoading = true;
        this.$api.getData("board/useUnit").then(res => {
          this.useUnitData = res.data;
          for (let i = 0; i < this.useUnitData.length; i++) {
            this.geocodeUnitSearch(i);
          }
          this.mapLoading = false;
        }).catch(error => {
          this.mapLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      geocodeUnitSearch(index) {
        let obj = this.useUnitData[index];
        let item = {
          name: obj.useUnitName,
          value: [obj.lng, obj.lat, obj.elevatorCount, obj.useUnitId],
        };
        this.chartData.push(item);
        if (index === this.useUnitData.length - 1) {
          this.chart.setOption(this.getChartOption());
        }
      },
      getChartOption() {
        return {
          backgroundColor: null,
          title: {
            subtext: "",
            sublink: "",
            left: "center",
            textStyle: {
              color: "#333",
            },
          },
          tooltip: {
            trigger: "item",
            formatter: function(params) {
              return params.name + " : " + params.value[2];
            },
          },
          legend: {
            orient: "vertical",
            y: "bottom",
            x: "right",
            data: ["数量"],
            textStyle: {
              color: "#389dff",
            },
          },
          geo: {
            map: "china",
            zoom: 1.2,
            label: {
              emphasis: {
                show: false,
              },
            },
            roam: true,
            itemStyle: {
              normal: {
                areaColor: "#001FA3",
                borderColor: "#389dff",
                borderWidth: 0.5,
              },
              emphasis: {
                areaColor: "#184cff",
              },
            },
          },
          series: [
            {
              name: "数量",
              type: "scatter",
              coordinateSystem: "geo",
              data: this.chartData,
              symbolSize: function(val) {
                if (val[2] >= 1 && val[2] <= 5) {
                  return 2;
                }
                if (val[2] > 5 && val[2] <= 10) {
                  return 6;
                }
                if (val[2] > 400) {
                  return 40;
                }
                if (val[3] == 2323) {
                  return 20;
                }
                return Math.ceil(val[2] / 10);
              },
              label: {
                normal: {
                  formatter: "{b}",
                  position: "right",
                  show: false,
                },
                emphasis: {
                  show: true,
                },
              },
              itemStyle: {
                normal: {
                  color: "#DBB842",
                },
              },
            },
            {
              name: "Top 5",
              type: "effectScatter",
              coordinateSystem: "geo",
              data: this.chartData.sort(function(a, b) {
                return b.value - a.value;
              }).slice(0, 5),
              symbolSize: function(val) {
                return Math.ceil(val[2] / 50) + 1;
              },
              showEffectOn: "render",
              rippleEffect: {
                brushType: "stroke",
              },
              hoverAnimation: true,
              itemStyle: {
                normal: {
                  color: "#DBB842",
                  shadowBlur: 10,
                  shadowColor: "#2d8cf0",
                },
              },
              zlevel: 1,
            },
          ],
        };
      },
    },
  };
</script>
