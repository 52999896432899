<template>
  <div style="width: 100%; height: 100%;">
    <div :id="id" style="width: 100%; height: 100%;"></div>
  </div>
</template>
<script>

  import highcharts from "highcharts";
  import highcharts3d from "highcharts/highcharts-3d";
  import cylinder from "highcharts/modules/cylinder";

  highcharts3d(highcharts);
  cylinder(highcharts);

  export default {
    props: {
      id: {
        type: String,
      },
      categories: {
        type: Array,
      },
      data: {
        type: Array,
      },
      yAxisTxt: {
        type: String,
      },
      colors: {
        type: Array,
      },
    },
    data() {
      return {
        chart: {},
      };
    },
    watch: {
      data: function() {
        this.reDraw();
      },
    },
    mounted() {
      this.init();
    },
    methods: {
      init() {
        this.chart = highcharts.chart(this.id, {
          chart: {
            type: "cylinder",
            backgroundColor: null,
            options3d: {
              enabled: true,//使用3d功能
              alpha: 1,
              beta: 6,
              depth: 100,
              viewDistance: 25,// 视图距离，它对于计算角度影响在柱图和散列图非常重要。此值不能用于3D的饼图
              frame: { // Frame框架，3D图包含柱的面板，我们以X ,Y，Z的坐标系来理解，X轴与 Z轴所形成
                // 的面为bottom，Y轴与Z轴所形成的面为side，X轴与Y轴所形成的面为back，bottom、
                // side、back的属性一样，其中size为感官理解的厚度，color为面板颜色
                bottom: {
                  size: 2,
                },
                side: {
                  size: 1,
                  color: "transparent",
                },
                back: {
                  size: 1,
                  color: "transparent",
                },
              },
            },
          },
          credits: {//去掉水印logo
            enabled: false,
          },
          title: {
            text: "",//图表的标题文字
          },
          subtitle: {
            text: "",//副标题文字
          },
          legend: {
            enabled: false,
          },
          plotOptions: {
            column: {
              depth: 25,
            },
          },
          xAxis: {
            categories: this.categories,
          },
          yAxis: {
            title: {
              text: this.yAxisTxt,
            },
          },
          colors: this.colors,
          series: [
            {
              name: "",//统一的前置词,非必须
              data: this.data,
            },
          ],
        });
      },
      reDraw() {
        let length = this.chart.series.length;
        for (let i = 0; i < length; i++) {
          this.chart.series[i].setData(this.data);
        }
        let length2 = this.chart.xAxis.length;
        for (let i = 0; i < length2; i++) {
          this.chart.xAxis[i].setCategories(this.categories);
        }
        this.chart.redraw();
      },
    },
  };
</script>
